import axios_pro,{ init_api_pre, hotel_api_pre } from 'mc/axios_pro';


export function getCustomerAuth(params) {
    return new Promise((resolve, reject) => {
        axios_pro.post(init_api_pre+"getCustomerAuth", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
} 
export function getLinkStyle(params) {
    return new Promise((resolve, reject) => {
        axios_pro.post(init_api_pre+"getLinkStyle", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
} 
//获取登录类型
export function getLoginType(params) {
    return new Promise((resolve, reject) => {
        axios_pro.post(init_api_pre+"getLoginType", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
} 
//设置数据库
export function setDataBaseConfig(params) {
    return new Promise((resolve, reject) => {
        axios_pro.post(init_api_pre+"setDataBaseConfig", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}
//检测数据库链接状态
export function testConnectDatabase(params) {
    return new Promise((resolve, reject) => {
        axios_pro.post(init_api_pre+"testConnectDatabase", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}
//判断客户状态
export function getCustomerStatus(params) {
    return new Promise((resolve, reject) => {
        axios_pro.post(init_api_pre+"getCustomerStatus", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}

//生成二维码
export function createXcxPageQrCode(params) {
    return new Promise((resolve, reject) => {
        axios_pro.post(init_api_pre+"createXcxPageQrCode", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}

//创建新客户
export function newCustomer(params) {
    return new Promise((resolve, reject) => {
        axios_pro.post(init_api_pre+"newCustomer", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}

//下载最新版本系统
export function downLoadVersion(params) {
    return new Promise((resolve, reject) => {
        axios_pro.post(init_api_pre+"downLoadVersion", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}

//获取服务商第一个酒店的账号和密码
export function getNamePwdOfFirstShop(params) {
    return new Promise((resolve, reject) => {
        axios_pro.post(init_api_pre+"getNamePwdOfFirstShop", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}

//初始化数据库 
export function initDataBase(params, version) {
    return new Promise((resolve, reject) => {
        axios_pro.post(hotel_api_pre(version) +"/agency/Agency/initDataBase", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}


//代理端登陆
export function operatorLogin(params, version) {
    return new Promise((resolve, reject) => {
        axios_pro.post(hotel_api_pre(version) +"/agency/Agency/operatorLogin", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}
//创建一个总店
export function createShop(params, version) {
    return new Promise((resolve, reject) => {
        axios_pro.post(hotel_api_pre(version) +"/agency/shop/createShop", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}
//更新店铺权限
export function updateShopAuth(params,version) {
    return new Promise((resolve, reject) => {
        axios_pro.post(hotel_api_pre(version) +"/agency/shop/updateShopAuth", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}
//退出登陆
export function loginOut(params,version) {
    return new Promise((resolve, reject) => {
        axios_pro.post(hotel_api_pre(version) +"/agency/agency/loginOut", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}

//登陆酒店
export function login(params, version) {
    return new Promise((resolve, reject) => {
        axios_pro.post(hotel_api_pre(version) +"/admin/Admin/login", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}



