import React, { Component } from "react";
import './App.css';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import moment from "moment";
import { Button, ConfigProvider, Result } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import "moment/locale/zh-cn";

import DownLoadWeb from "./pages/DownLoadWeb";

moment.locale("zh-cn");

class App extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    // let {dispatch,history,admin_login_status,admin_msg}=this.props;
    // dispatch(Actions.admin.checkLoginStatus());
  }

  render() {
    // let pathname;
    // if(sessionStorage['pathname']){
    //     pathname=sessionStorage['pathname'];
    // }else{
    //     pathname=window.location.pathname;
    //     sessionStorage['pathname'] = pathname;
    // }
    // sessionStorage.clear();

    return (
      <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
        <DownLoadWeb />
      </div>
    );
  }
}

export default App;
